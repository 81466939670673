import "../legal.css";

import * as React from "react";
import { useTranslation } from "react-i18next";
// import { Trans } from "react-i18next"
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
// import { Parallax } from "react-scroll-parallax";

import Layout from "../../../components/layout/Layout";
import Seo from "../../../components/seo";
import SectionLanding from "../../../components/section/sectionLanding";
import Mask3 from "../../../svg/masks/mask3.svg";
import { Link } from "gatsby-plugin-react-i18next";

export default function IndexPage() {
	const { t } = useTranslation();
	return (
		<Layout>
			<Seo title={t("Aviso legal")} />

			<StaticImage
				src={`../../../images/contacto.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			{/* <Parallax speed={-10} className="parallaxImg">
				<StaticImage
					src={`../../../images/contacto.jpg`}
					className="landing-bg"
					alt="Section background"
					layout="fullWidth"
				/>
			</Parallax> */}
			<Mask3 />

			<SectionLanding title={t("Aviso legal")} />

			<div className="legalBody invertColors" id="avisoLegal">
				<h1>AVISO LEGAL Y CONDICIONES DE USO DEL PORTAL</h1>
				<h2>INFORMACIÓN LEGAL Y ACEPTACIÓN</h2>
				<p>
					En este espacio, el usuario, podrá encontrar toda la información
					relativa a los términos y condiciones legales que definen las
					relaciones entre los usuarios y BEGIN RESTAURANTES, S.L. como
					responsable del presente portal de Internet y como responsable de las
					redes sociales (en adelante, conjuntamente, como el “Portal”, “Web”, o
					“Sitio Web”). Como usuario, es importante que conozcas estos términos
					antes de continuar tu navegación.
					<br />
					<br />
					Los datos aquí consignados corresponden a BEGIN RESTAURANTES, S.L.,
					responsable del Sitio Web, en cumplimiento con el deber de información
					recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de
					Servicios de la Sociedad de la Información y del Comercio Electrónico
					(en adelante, “LSSICE”). En particular, estos son los siguientes:
					<br />
					– Titular: BEGIN RESTAURANTES S.L. (la “Sociedad”)
					<br />
					– N.I.F.: B-40.626.392
					<br />
					– Domicilio: C/ Gobernador Viejo, número 31, bajo, 46003, Valencia,
					España.
					<br />
					– Email: contacto@beginrestaurante.com
					<br />
					– Actividad: La explotación de establecimientos abiertos al público en
					el sector de la hostelería, tales como restaurantes, bares y
					cafeterías, así como cualquier otra actividad de servicios que tenga
					relación con el sector de la hostelería.
					<br />
					– Inscrita en el Registro Mercantil de Valencia, al tomo 10.754, folio
					130, hoja V-190.498.
					<br />
					<br />
					Lo regulado por medio del presente texto es de aplicación tanto al
					presente Portal de Internet como a las redes sociales titularidad de
					la Sociedad.
					<br />
					<br />
					La utilización del Portal atribuye la condición de usuario del Portal
					(en adelante, individualmente, el “Usuario”, y conjuntamente, los
					“Usuarios”) e implica la aceptación de todas las condiciones incluidas
					en el presente documento (en adelante, “Aviso Legal”) que se encuentre
					vigente en esta dirección, así como la política de privacidad (en
					adelante, la “Política de Privacidad”) y la política de cookies (en
					adelante, la “Política de Cookies”).
					<br />
					<br />
					El acceso al Portal es gratuito, salvo en lo relativo al coste de la
					conexión a través de la red de telecomunicaciones suministrada por el
					proveedor de acceso contratado por los Usuarios.
					<br />
					<br />
					La prestación del servicio del Portal tiene una duración limitada al
					momento en el que el Usuario se encuentre conectado al Portal o a
					alguno de los servicios que a través del mismo se facilitan. Por
					tanto, el Usuario debe leer atentamente el presente Aviso Legal y la
					Política de Privacidad en cada una de las ocasiones en que se proponga
					utilizar el Portal, ya que las condiciones recogidas en el Aviso Legal
					y la Política de Privacidad pueden sufrir modificaciones.
				</p>
				<h2>LEY APLICABLE Y JURISDICCIÓN</h2>
				<p>
					La Sociedad, como responsable del Portal, asume el compromiso de
					procesar la información de sus Usuarios con plenas garantías y cumplir
					con los requisitos nacionales y europeos que regulan el tratamiento y
					uso de los datos personales de sus Usuarios.
					<br />
					<br />
					Con carácter general, las relaciones entre la Sociedad y los Usuarios,
					se encuentran sometidas a la legislación y jurisdicción españolas.
					<br />
					<br />
					Las partes renuncian expresamente al fuero que les pudiera
					corresponder y someten expresamente a los Juzgados y Tribunales de
					Valencia para resolver cualquier controversia que pueda surgir en la
					interpretación o ejecución de las presentes condiciones contractuales,
					incluyendo este Aviso Legal, la Política de Privacidad, y la Política
					de Cookies y, en general, por el uso del Portal o por todos los
					servicios prestados por la Sociedad.{" "}
				</p>
				<h2>PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
				<p>
					http://www.beginrestaurante.com/ es el Portal de la Sociedad, a través
					del cual se prestan diversos servicios de información a sus
					visitantes. Al visitar y utilizar el Portal, usted acepta el hecho de
					que la Sociedad se reserva el derecho a modificar o suspender las
					páginas disponibles, el sitio web, o a borrar temporal o
					definitivamente los datos que ha ofrecido, en cualquier momento, sin
					previo aviso y sin ningún tipo de responsabilidad con los Usuarios. De
					esta forma, usted acepta que la Sociedad no tiene responsabilidad
					alguna por el borrado de informaciones, fallos en su almacenamiento,
					inexactitud de los contenidos y otras circunstancias relativas a la
					información de su página web.
					<br />
					<br />
					Los derechos de propiedad intelectual del contenido del Portal, a
					título meramente enunciativo, los textos, fotografías, gráficos,
					imágenes, iconos, tecnología software, links, y demás contenidos
					audiovisuales o sonoros, su diseño gráfico y códigos (en adelante, los
					“Contenidos”) son titularidad de la Sociedad y, por tanto, queda
					prohibida su reproducción, distribución, comunicación pública,
					transformación o cualquier otra actividad que se realice con los
					contenidos del Portal, ni siquiera aunque se citen las fuentes, salvo
					que se cuente con el consentimiento previo, expreso y por escrito de
					la Sociedad. Todos los nombres comerciales, marcas o signos distintos
					de cualquier clase contenidos en las páginas web de la empresa son
					propiedad de sus dueños y están protegidos por ley. No se reconoce en
					ningún caso cedidos al Usuario ninguno de los derechos de explotación
					reconocidos por la normativa vigente en materia de propiedad
					intelectual sobre los mismo, salvo aquellos que resulten estrictamente
					necesarios para el uso del Portal.
					<br />
					<br />
					La Sociedad no concede ninguna licencia o autorización de uso de
					ninguna clase sobre sus derechos de propiedad intelectual e industrial
					o sobre cualquier otra propiedad o derecho relacionado con el Portal,
					y en ningún caso se entenderá que el acceso y navegación de los
					usuarios implica una renuncia, transmisión, licencia o cesión total ni
					parcial de dichos derechos por parte de la Sociedad.
					<br />
					<br />
					Cualquier uso de esos contenidos no autorizado previamente por parte
					de la Sociedad será considerado un incumplimiento grave de los
					derechos de propiedad intelectual o industrial y dará lugar a las
					responsabilidades legalmente establecidas. La Sociedad se reserva el
					derecho de ejercitar frente al Usuario las acciones judiciales y
					extrajudiciales que correspondan.
				</p>
				<h2>CONDICIONES DE USO DEL PORTAL</h2>
				<h3>Aceptación del usuario</h3>
				<p>
					Este Aviso Legal regula el acceso y utilización del Portal que la
					Sociedad pone a la disposición de los Usuarios.
					<br />
					<br />
					El Usuario queda informado, y acepta, que el acceso al presente Portal
					no supone, en modo alguno, el inicio de una relación comercial con la
					Sociedad
				</p>
				<h3>Acceso al portal</h3>
				<p>
					El acceso al Portal por parte de los Usuarios es de carácter libre y
					gratuito. Algunos servicios pueden encontrarse sujetos a contratación
					previa del servicio.
					<br />
					<br />
					En caso de ser necesario que el Usuario aporte datos personales para
					acceder a alguno de los servicios, la recogida y el tratamiento de los
					datos se realizará de conformidad con la normativa vigente, en
					concreto con la Ley Orgánica 3/2018, de 5 de diciembre, de Protección
					de Datos de Personales y garantía de los derechos digitales (en
					adelante, la “LOPD”), y con el Reglamento (UE) 2016/679 del Parlamento
					Europeo y del Consejo de 27 de abril de 2016 relativo a la protección
					de las personas físicas en lo que respecta al tratamiento de datos
					personales y a la libre circulación de estos datos y por el que se
					deroga la Directiva 95/46/CE (en adelante, el “RGPD”). Para más
					información, consulte nuestra
					<Link to="/legal/pollitica-privacidad">
						<u>Política de Privacidad</u>
					</Link>
					.
				</p>
				<h3>Contenido y uso</h3>
				<p>
					La visita al Portal por parte del Usuario deberá hacerse de forma
					responsable y de conformidad a la legalidad vigente, la buena fe, el
					presente Aviso Legal, la Política de Privacidad, y la Política de
					Cookies respetando los derechos de propiedad intelectual e industrial
					titularidad de la Sociedad o cualquier otra persona física o jurídica.
					<br />
					<br />
					Queda expresamente prohibido el uso del Portal con fines lesivos de
					bienes o intereses de la Sociedad o de terceros o que, de cualquier
					otra forma sobrecarguen, dañen o inutilicen las redes, servidores y
					demás equipos informáticos (hardware) o productos y aplicaciones
					informáticas (software) de la Sociedad o de terceros. El Usuario
					responderá frente a la Sociedad o frente a terceros, de cualesquiera
					daños y perjuicios que pudieran causarse como consecuencia del
					incumplimiento de dicha obligación.
					<br />
					<br />
					Con carácter meramente enunciativo y no limitativo, el Usuario, de
					acuerdo con la legislación vigente, deberá abstenerse de:
					<br />
					- Reproducir, copiar, distribuir, poner a disposición, comunicar
					públicamente, transformar o modificar los Contenidos, salvo en los
					casos autorizados en la ley o expresamente consentidos por la
					Sociedad, o por quien ostente la titularidad de los derechos de
					explotación en su caso.
					<br />
					- Reproducir o copiar para uso privado los Contenidos que puedan ser
					considerados como Software o Base de Datos de conformidad con la
					legislación vigente en materia de propiedad intelectual, así como su
					comunicación pública o puesta a disposición de terceros cuando estos
					actos impliquen necesariamente la reproducción por parte del Usuario o
					de un tercero.
					<br />
					- Extraer y/o reutilizar la totalidad o una parte sustancial de los
					Contenidos integrantes del Portal, así como de las bases de datos que
					la Sociedad ponga a disposición de los Usuarios.
					<br />
					<br />
					El uso de cualquiera de los contenidos del Portal con finalidades que
					sean o pudieran ser ilícitas queda totalmente prohibido, así como la
					realización de cualquier acción que cause o pueda causar daños o
					alteraciones de cualquier tipo (que dañen, sobrecarguen, inutilicen
					las redes, servidores y demás equipos informáticos -hardware- o
					productos y aplicaciones informáticas software-) no consentidas por la
					Sociedad, al Sitio Web, a sus Contenidos o a terceros.
					<br />
					<br />
					La Sociedad, como titular del Portal, no se identifica con las
					opiniones vertidas en el mismo por los Usuarios. La Sociedad se
					reserva el derecho de efectuar sin previo aviso las modificaciones que
					considere oportunas en el Portal, pudiendo cambiar, suprimir o añadir
					tanto los Contenidos y servicios que se presten a través de la misma
					como la forma en la que éstos aparezcan presentados o localizados en
					sus servidores.
				</p>
				<h3>Introducción de enlaces al Portal</h3>
				<p>
					La presencia de enlaces (links) en el Portal hacia otros sitios de
					Internet tiene finalidad meramente informativa y en ningún caso
					suponen sugerencia, invitación o recomendación sobre los mismos.
					<br />
					<br />
					En el caso de que en otros sitios web se establezcan enlaces al Portal
					de la Sociedad, no se entenderá que la Sociedad ha autorizado el
					enlace o el contenido del sitio web en el que se contiene el enlace,
					tampoco podrá incluirse en la página del enlace contenidos
					inapropiados, difamatorios, ilegales, obscenos o ilícitos, ni otros
					contenidos que sean contrarios a la legalidad vigente.
					<br />
					<br />
					En el caso de que un Usuario quisiera introducir enlaces desde sus
					propias páginas web al Portal, deberá cumplir con las condiciones que
					se detallan a continuación sin que el desconocimiento de las mismas
					evite las responsabilidades derivadas de la Ley.
					<br />
					<br />
					El enlace únicamente vinculará con la página principal del Portal,
					pero no podrá reproducirla de ninguna forma (inline links, copia de
					los textos, gráficos, etc.). Quedará en todo caso prohibido, de
					acuerdo con la legislación aplicable y vigente en cada momento,
					establecer frames o marcos de cualquier tipo que envuelvan al Portal o
					permitan la visualización de los contenidos a través de direcciones de
					Internet distintas a las del Portal y, en cualquier caso, cuando se
					visualicen conjuntamente con contenidos ajenos al Portal de forma que:
					(i) produzca, o pueda producir, error, confusión o engaño en los
					Usuarios sobre la verdadera procedencia del servicio o contenidos;
					(ii) suponga un acto de comparación o imitación desleal; (ii) sirva
					para aprovechar la reputación de y prestigio de la Sociedad; o (iv) de
					cualquier otra forma resulte prohibido por la legislación vigente.
					<br />
					<br />
					No se realizarán desde la página que introduce el enlace ningún tipo
					de manifestación falsa, inexacta o incorrecta sobre la Sociedad, sus
					empleados, o sobre la calidad de los servicios que presta.
					<br />
					<br />
					En ningún caso, se expresará en la página donde se ubique el enlace
					que la Sociedad ha prestado su consentimiento para la inserción del
					enlace o que de otra forma patrocina, colabora, verifica o supervisa
					los servicios del remitente.
					<br />
					<br />
					Está prohibida la utilización de cualquier marca denominativa, gráfica
					o mixta o cualquier otro signo distintivo de la Sociedad dentro de la
					página del remitente, salvo en los casos permitidos por la Ley o
					expresamente autorizados por la Sociedad y siempre que se permita, en
					estos casos, un enlace directo con el Portal en la forma establecida
					en esta cláusula. La página que establezca el enlace deberá cumplir
					fielmente con la Ley y no podrá en ningún caso disponer o enlazar con
					contenidos propios o de terceros que: (i) sean ilícitos, nocivos o
					contrarios a la moral y a las buenas costumbres; (ii) induzcan o
					puedan inducir la falsa concepción de que la Sociedad suscribe,
					respalda, se adhiere o de cualquier manera apoya, las ideas,
					manifestaciones o expresiones, lícitas o ilícitas, del remitente;
					(iii) resulten inapropiados o no pertinentes con la actividad de la
					Sociedad en atención al lugar, contenidos y temática de la página Web
					del remitente.
					<br />
					<br />
					En el supuesto de que el Usuario considere que existe una página que
					facilita un enlace al Portal con contenidos ilícitos o inadecuados
					podrá comunicárselo a la Sociedad, procediendo en estos casos la
					Sociedad a realizar las oportunas averiguaciones, con el fin de llevar
					a cabo las actuaciones que procedan.
					<br />
					<br />
					En ningún caso, la existencia de un enlace al Portal debe presuponer
					la existencia de acuerdos con los responsables o titulares de la
					página que facilita dicho enlace, ni la recomendación, promoción o
					identificación de la Sociedad con las manifestaciones, contenidos o
					servicios proveídos. La Sociedad no conoce los contenidos y servicios
					de dichas páginas, y por tanto no se hace responsable por los daños
					producidos por la ilicitud, calidad, desactualización,
					indisponibilidad, error e inutilidad de los contenidos y/o servicios
					de en ella proveídos, ni por cualquier otro daño.
					<br />
					<br />
					La Sociedad se reserva la posibilidad de contactar con el propietario
					del sitio web en el que se establezca el enlace si considera que se
					incumple la normativa, así como de ejercitar las correspondientes
					acciones judiciales y extrajudiciales.
				</p>
				<h3>Responsabilidad y garantías</h3>
				<p>
					El Usuario es consciente de su exclusiva responsabilidad por la
					utilización que efectúe del Portal de conformidad con el presente
					Aviso Legal, la Política de Privacidad, y la Política de Cookies,
					eximiendo a la Sociedad de responsabilidad de cualquier tipo, de
					demanda o reclamación, incluyendo los honorarios de representación
					jurídica aunque no fuese preceptiva, que pudiera ser interpuesta por
					un tercero, debido a o como resultado de su conducta o su conexión a
					este sitio Web, los Contenidos que ofrece, su violación de este Aviso
					Legal y la Política de Privacidad o de cualquier otra violación de los
					derechos de otra persona o parte.
					<br />
					<br />
					La Sociedad declara que ha adoptado las medidas necesarias que, dentro
					de sus posibilidades y el estado de la tecnología, permitan el
					correcto funcionamiento del Portal, así como la ausencia de virus y
					componentes dañinos. A título enunciativo, pero no limitativo, la
					Sociedad no puede hacerse responsable de las siguientes situaciones:
					<br />
					- La continuidad y disponibilidad de los Contenidos.
					<br />
					- La ausencia de errores en dichos Contenidos ni la corrección de
					cualquier defecto que pudiera ocurrir.
					<br />
					- La ausencia de virus y/o demás componentes dañinos.
					<br />
					- Los daños producidos en los equipos informáticos de los Usuarios o
					de terceros durante la prestación del servicio del Portal.
					<br />
					- Los daños o perjuicios que cause cualquier persona que vulnere los
					sistemas de seguridad de la Sociedad.
					<br />
					- El uso que los Usuarios puedan hacer de los Contenidos. En
					consecuencia, la Sociedad no garantiza que el uso que los Usuarios
					puedan hacer de los Contenidos que en su caso se incluyan en el Sitio
					Web, se ajusten al presente Aviso Legal, ni que el uso del Portal se
					realice de forma diligente.
					<br />
					- El uso por menores de edad del Portal o del envío de sus datos
					personales sin el permiso de sus tutores, siendo los tutores
					responsables del uso que hagan de Internet.
					<br />
					- Los Contenidos a los que el Usuario pueda acceder a través de
					enlaces no autorizados o introducidos por Usuarios mediante
					comentarios o herramientas similares.
					<br />
					- La introducción de datos erróneos por parte del Usuario o de un
					tercero.
					<br />
					- Los daños o perjuicios de cualquier tipo producidos en el Usuario,
					que traigan causa de fallos o desconexiones en las redes de
					telecomunicaciones que produzca la suspensión, calculación o
					interrupción del servicio del Portal durante la prestación del mismo o
					con carácter previo.
					<br />
					- La Sociedad no se hace responsable de la licitud, veracidad,
					fiabilidad, exactitud o comerciabilidad de los Contenidos, salvo en la
					medida que por Ley no puedan excluirse tales declaraciones y
					garantías.
					<br />
					- El acceso a las redes requiere de servicios y suministros de
					terceros, incluidos el transporte a través de redes de
					telecomunicaciones, cuya fiabilidad, calidad, continuidad y
					funcionamiento no corresponde a la Sociedad. La Sociedad podrá
					suspender temporalmente o definitivamente y sin previo aviso, la
					accesibilidad al Portal con motivo de operaciones de mantenimiento,
					reparación, actualización o mejora. No obstante, siempre que las
					circunstancias lo permitan, la Sociedad comunicará al Usuario, con
					antelación suficiente, la fecha prevista para la suspensión de los
					Contenidos.
					<br />
					<br />
					Asimismo, de conformidad con los arts. 11 y 16 de la LSSICE, la
					Sociedad se compromete a la eliminación o, en su caso, bloqueo de los
					contenidos que pudieran afectar o ser contrarios a la legislación
					vigente, los derechos de terceros o la moral y orden público.
				</p>
				<h3>Responsabilidad del usuario</h3>
				<p>
					El Usuario es consciente de su exclusiva responsabilidad por la
					utilización que efectúe del Portal de conformidad con el presente
					Aviso Legal, la Política de Privacidad, y la Política de Cookies,
					eximiendo a la Sociedad de responsabilidad de cualquier tipo, de
					demanda o reclamación, incluyendo los honorarios de representación
					jurídica aunque no fuese preceptiva, que pudiera ser interpuesta por
					un tercero, debido a o como resultado de su conducta o su conexión a
					este sitio Web, los Contenidos que ofrece, su violación de este Aviso
					Legal, la Política de Privacidad, la Política de Cookies, o de
					cualquier otra violación de los derechos de otra persona o parte.
					<br />
					<u>Limitación de responsabilidad general</u>
					<br />
					El Usuario entiende explícitamente y acepta que la Sociedad no será
					responsable de ningún daño directo, indirecto, extraordinario o
					incidental, incluyendo a título enunciativo y no limitativo, daños por
					la pérdida de beneficios, datos u otras pérdidas intangibles (incluso
					si la Sociedad ha notificado la posibilidad de estas pérdidas), como
					resultado de:
					<br />
					- el uso o la imposibilidad de utilizar el Portal.
					<br />
					- el coste para obtener sustitución de los bienes como resultado de
					cualquier operación realizada en o a través del Portal.
					<br />
					- un acceso no autorizado o la alteración de la transmisión de sus
					datos.
					<br />
					- la cesión o transmisión no autorizada por la Sociedad, de datos de
					carácter personal realizada por el Usuario o por cualquier tercero.
					<br />
					- cualesquiera otras circunstancias.
					<br />
					<br />
					Asimismo, se deja expresa constancia que la Sociedad no ofrece
					garantías, implícitas o explícitas, sobre la no interrupción de acceso
					al Portal, la falta de errores, la ausencia de virus, la puntualidad,
					la seguridad, la precisión, la confianza, en ninguna cualidad, así
					como la necesidad de que cualquier contenido sea seguro en cualquiera
					de los sentidos. El Usuario acepta que este Aviso Legal, la Política
					de Privacidad, y la Política de Cookies pueden sufrir modificaciones
					que serán objeto de adecuada publicidad en el Portal.
				</p>
				<h3>Duración y terminación</h3>
				<p>
					La prestación del servicio del presente Sitio Web y los demás
					servicios tienen en principio una duración indefinida. No obstante, la
					Sociedad podrá dar por terminada o suspender cualquiera de los
					servicios del Portal. Cuando sea ello posible, la Sociedad anunciará
					la terminación o suspensión de la prestación del servicio determinado.
				</p>
				<h3>Declaraciones y garantías</h3>
				<p>
					En general, los Contenidos y servicios ofrecidos en el Portal tienen
					carácter meramente informativo. Por consiguiente, al ofrecerlos, la
					Sociedad no otorga garantía ni declaración alguna en relación con los
					Contenidos y servicios ofrecidos en el Portal, incluyendo, a título
					enunciativo, garantías de licitud, fiabilidad, utilidad, veracidad,
					exactitud, o comerciabilidad, salvo en la medida en que por ley no
					puedan excluirse tales declaraciones y garantías.
				</p>
				<h3>Fuerza mayor</h3>
				<p>
					La Sociedad no será responsable en todo en caso de imposibilidad de
					prestar servicio, si ésta se debe a interrupciones prolongadas del
					suministro eléctrico, líneas de telecomunicaciones, conflictos
					sociales, huelgas, rebelión, explosiones, inundaciones, actos y
					omisiones del Gobierno, y en general todos los supuestos de fuerza
					mayor o de caso fortuito.
				</p>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
